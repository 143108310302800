<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Classificação'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        @input="__set"
        :placeholder="placeholder || 'Nenhuma'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
// import Dados from '../../../../domain/remocao/helpers/Classificacao'
import {listaClassificacaoRemocao} from '@/domain/remocao/services'

export default {
  xname: 'ClassificacaoSelect',
  mixins: [SelectMixin],
  props: {
  },
  data () {
    return {
      asyncdata: []
    }
  },
  mounted () {
    this.load()
  },
  watch: {
    value (val) {
      this.$emit('selected', this.asyncdata.find(v => v.id === val))
    }
  },
  computed: {
    /*asyncdata () {
      let list = Dados.map((obj) => {
        return {
          ...obj,
          label: obj.nome,
          value: obj.id
        }
      }).sort((a, b) => {
        if (a.nome > b.nome) {
          return 1;
        }
        if (a.nome < b.nome) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
      !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Nenhuma', value: null})
      return list
    }*/
  },
  components: {},
  methods: {
    load () {
      this.loading = true
      listaClassificacaoRemocao(100, 1)
          .then((response) => {
            this.loading = false
            if (!Array.isArray(response.data.result)) {
              return
            }
            this.$emit('load', response.data.result)
            let list = response.data.result.map((obj) => {
              return {
                ...obj,
                label: obj.nome,
                value: obj.id
              }
            })

            !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
            this.asyncdata = list
          })
          .catch((error) => {
            this.loading = false

          })
    }
  }
}
</script>
